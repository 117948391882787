/*Main app*/
body {
  background-color: #333;
  color: white;
  font-family: 'Arial', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

/*sidebar*/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #333;
  color: white;
  width: 200px;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  z-index: 1000;
}

.sidebar.collapsed {
  transform: translateX(0);
}

.toggle-button {
  position: absolute;
  top: 10px;
  left: 210px;
  background-color: #333;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  font-size: 170%;
}

.toggle-button:hover {
  background-color: #555;
}

.menu {
  list-style-type: none;
  padding: 20px 10px;
  text-align: left;
}

.menu li {
  margin: 20px 0;
}

.menu a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  padding-left: 15px;
  transition: color 0.3s ease;
}

.menu a:hover {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 4px;
  padding: 4px;
  font-size: 18px;
}

.sidebar.collapsed ~ .content {
  margin-left: 200px;
}

.AdFreeButton{
  position: absolute;
  right: 2%;
  top: 2%;
  background: none;
  border: solid white 2px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 3px;
  padding-left: 3px;
}

.header-image {
  text-align: center;
  margin-top: 20px;
}

.Title-image {
  max-width: 20%;
  height: auto;
  border-radius: 8px;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.button {
  background: linear-gradient(135deg, blue, purple);
  border-radius: 8px;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
  font-size: 1.5rem;
  width: 400px;
  max-width: 300px;
  height: 100px;
  margin: 10px auto;
  border: 0px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: transparent;
  border-color: #b19cd9;
}

/*Restaurant and order pages*/
.card {
  position: absolute; /* Position it in the center */
  background-color: #444;
  margin-top: 5%;
  padding: 60px;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 8px;
  max-width: 250px;
  height: 25%;
  text-align: center;
  font-size: 1.2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.close-button {
  position: absolute;
  left: 3%;
  top: 3%;
  background: none;
  border: none;
  color: black;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
  padding-left: 1px;
  padding-right: 1px;
  
}

.card p {
  margin: 0;
}

.card:hover {
  transform: scale(1.05);
}

.directions-button {
  position: absolute; /* Positioning at the bottom of the card */
  bottom: 12px;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  background-color: transparent; /* Transparent background */
  color: white; /* White text */
  border: 2px solid white; /* White border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Padding for click area */
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth hover effect */
}

.directions-button:hover {
  background-color: white;
  color: black; /* Invert colors on hover */
}

.search-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.input {
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 0px;
  color: #060606;
  margin: 5px; /* Add space between input and button */
  width: 100%;
  max-width: 250px; /* Adjust width if necessary */
}

.input:focus {
  outline: none;
  border-color: blue;
}

.enterButton {
  padding: 10px 20px;
  height: 100%;
  color: white;
  font-weight: bold; 
  width: 80px;
  background: linear-gradient(135deg, blue, purple);
  border-radius: 8px;
  border: 0px solid transparent;/* Adjust padding for button size consistency */
  cursor: pointer;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

.optional-label {
  width: 80px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 0px;
}

.or-field{
  margin: 10px;
  font-size: 2rem;
}

.generic-button {
  background: linear-gradient(135deg, blue, purple);
  border-radius: 8px;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
  font-size: 1.3rem;
  width: 200px;
  max-width: 300px;
  height: 80px;
  margin: 10px auto;
  border: 0px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.image-container {
  text-align: center;
  margin-top: 20px;
}

.Or-image {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
}

.dropdown {
  position: absolute; /* Position dropdown relative to the container */
  top: 92%; /* Aligns the dropdown just below the input */
  left: 1%; /* Aligns dropdown to the left of the input */
  width: 100%; /* Match the width of the input field */
  max-width: 270px;
  background: white;
  border: 0px transparent;
  border-radius: 12px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000; /* Make sure dropdown is above other content */
  color: black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  list-style: none; /* Removes default list styling (dots or bullets) */
  padding: 0; /* Removes any default padding */
  margin: 0; /* Removes any default margin */
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #eee;
}

/*Blog page*/
.blog-container {
  text-align: center;
  padding: 20px;
}

.blog-header {
  margin-bottom: 20px;
  font-size: 3em;
  font-weight: bold;
  padding-bottom: 50px;
}

.dropdown-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.dropdownpicker label {
  margin-right: 10px;
  font-weight: bold;
  font-size: 130%;
}

.dropdownpicker select {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 7px;
}

.post-content {
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid #fff ;
  background-color: transparent;
  font-weight: bold;
}

.post-content h2 {
  font-size: 1.7rem; 
  text-align: center; 
  margin: 20px 0;
}

/*how to use*/

.howTo-container{
  text-align: center;
  padding: '20px';
}

.howTo-header {
  margin-bottom: 20px;
  font-size: 3em;
  font-weight: bold;
  padding-bottom: 50px;
}

.howTo-image {
  text-align: center;
  margin-top: 20px;
}

/*whats new*/

.whatsNew-container{
  text-align: center;
  padding: '20px';
}

.whatsNew-header {
  margin-bottom: 20px;
  font-size: 3em;
  font-weight: bold;
  padding-bottom: 50px;
}